import messageTemplate from '@/plugins/i18n/message-template'

export default {
  // message template
  ...messageTemplate,

  // menu
  needMoreHelpTextIg: 'Visit Us on Instagram',
  needMoreHelpTextFb: 'See us on facebook',
  needMoreHelpTextEmail: 'Best way to get answer faster!',
  needMoreHelpText: 'contact us below. we will help you as soon as we can',
  needMoreHelp: 'Need more Help?',
  HelpCenter: 'Help Center',
  contactUs: 'Contact Us',
  eOrdering: 'eOrdering',
  eForm: 'eForm',
  member: 'Member',
  price: 'Price',
  startConversation: 'Start Conversation',
  customer: 'Customer',
  notes: 'Notes',
  lastMessage: 'Last Message',
  confirmDelete: 'Are you sure to delete?',
  deleteTemplate: 'Are you sure want to delete this template?',
  bulkDeleteTemplate: 'Are you sure want to delete all selected template?',
  messageTemplatesRead: 'Message Templates',
  Channels: 'Channels',
  Inbox: 'Inbox',
  Broadcast: 'Broadcasts',
  'Broadcast List': 'Broadcast List',
  'Scheduled Broadcast': 'Scheduled Broadcast',
  'List Broadcast': 'Broadcasts List',
  'Broadcast Schedule': 'Broadcasts Schedule',
  Statistic: 'Statistic',
  Contact: 'Contact',
  Contacts: 'Contacts',
  Label: 'Label',
  Widget: 'Widget',
  Template: 'Template',
  Operators: 'Operators',
  'Operators List': 'Operators List',
  'Replies History': 'Replies History',
  'Operator Statistic': 'Operator Statistic',
  Subscriptions: 'Subscriptions',
  'Link Generator': 'Link Generator',
  Chat: 'Chat',
  'Chat Data': 'Chat Data',
  'Live Chat Widget': 'Live Chat Widget',
  'Contact List': 'Contact List',
  contactGroup: 'Contact Group',
  Tutorial: 'Tutorial',
  'Help Center': 'Help Center',
  'Contact Us': 'Contact Us',
  'Business+': 'Business+',
  'Link Tracker': 'Link Tracker',

  // key
  freeTrialRemaining: 'Free trial remaining:',
  demoMessageSuccess: 'Yeay, your message has been sent!',
  update: 'Update',
  channel: 'channel',
  refresh: 'Refresh',
  message: 'message',
  List: 'List',
  Import: 'Import',
  scan: 'scan',
  Fields: 'Fields',
  field: 'field',
  value: 'value',
  setLabel: 'Set Label',
  config: 'config',
  logout: 'Logout',
  ok: 'OK',
  new: 'New',
  create: 'Create',
  add: 'Add',
  addTo: 'Add To',
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  warning: 'Warning',
  delete: 'Delete',
  deleteConfirmation: 'Are you sure you want to delete',
  archive: 'Archive',
  unarchived: 'Unarchive',
  sendyourfirstmessage: 'Send your first message',
  archivedConfirmation: 'Are you sure you want to Archived',
  unarchivedConfirmation: 'Are you sure you want to Unarchived',
  deleteMessage: 'Are you sure you want to delete this data?',
  warningfailedsync: 'Failed to sync from server refresh to retry',
  warningactiveinvoice: 'You have an active Xendit invoice',
  warningpackageexpired: 'Your workspace is expired',
  warningmaxchannel: 'You reached max Channel, upgrade package or buy extra to add more Channel',
  warningmaxoperator: 'You reached max Operator, upgrade package or buy extra to add more Operator',
  warningmaxcontacts: 'You reached max Contacts, upgrade package or buy extra to add more Contacts',
  warningfailedsavedata: 'Save data failed, please check your connection',
  syncfromserver: 'Sync data from server',
  pay: 'Pay',
  search: 'Search',
  select: 'Select',
  selectall: 'Select All',
  send: 'Send',
  submit: 'Submit',
  next: 'Next',
  previous: 'Previous',
  finish: 'Finish',
  close: 'Close',
  from: 'From',
  clear: 'Clear',
  rowsPerPageLabel: 'Records per page:',
  noDataLabel: 'No data available',
  loadingLabel: 'Loading data...',
  phoneNumber: 'Phone number',
  notFound: 'not found',
  noResults: 'No results',
  view: 'View',
  preview: 'Preview',
  tryAgain: 'Try Again',
  column: 'Column',
  source: 'Source',
  lastReply: 'Last Reply',
  favourite: 'Favourite',
  interaction: 'Interaction',
  needReply: 'Need Reply',
  reply: 'Reply',
  assignTo: 'Assign To',
  messageStatus: 'Message Status',
  name: 'Name',
  action: 'Action',
  continuousSync: 'Continuous Sync',
  continuousSyncInfo: 'Continuously sync contact with this sheet every 5 minutes',
  sheetName: 'Sheet Name',
  record: 'record',
  selectedOf: 'selected of',
  you: 'You',
  or: 'or',
  import: 'Import',
  link: 'Link',
  exportToCsv: 'Export to CSV',
  export: 'Export',
  changeSelectedData: 'Change Selected Data',
  changeLabel: 'Change Label',
  change: 'Change',
  deleteSelectedData: 'Delete Selected Data',
  year: 'Year',
  years: 'years',
  month: 'Month',
  monthsInterval: 'Months',
  months: 'months',
  day: 'day',
  days: 'days',
  min: 'min',
  mins: 'mins',
  hour: 'hour',
  hours: 'hours',
  second: 'second',
  seconds: 'seconds',
  chat: 'Chat',
  serverConnectionLost: 'Server Connection Lost',
  dialog: {
    invalidFile: 'Please choose a valid file and try again',
    requiredMessage: 'Message is required',
    channelRequired: 'Please select channel you want to import',
    mappingallfield: 'Please mapping field Name and Phone Number',
    failedgetdata: 'Failed to get data',
    notvalidsheet: 'Please specify a valid Spreadsheet URL',
    pleaseselectone: ' Please select contact',
    isRequired: ' is required',
    errorSendMessage: 'Cannot send message, please try again',
    errorUploadAttachment: 'Cannot send attachment, please try again',
    selectChannel: 'Please Select Channel',
    selectGroupContact: 'Please Select Contact Group',
    errorImportUser: 'Error Downloading Group User Data',
  },
  channels: {
    channel: 'Channel',
    label: 'Label',
    color: 'Color',
    phoneNumber: 'Phone Number',
    actions: 'Actions',
    backgroundMessage: 'Active Background Message',
    infoBackgroundMessage:
      'To avoid whatsapp spam detection you could activate this feature to increase number of incoming chat',
    syncSetting: 'Sync Setting',
    syncSettingOptionOne: 'All Messages',
    syncSettingOptionTwo: 'Only From Known Contacts',
    syncContact: 'Sync Contact',
    muteNotification: 'Mute Notification',
    boldUnreplied: 'Stealth Mode',
    infoStealthMode: 'Only mark chat as read when replied',
    connected: 'Connected',
    notConnected: 'Not Login',
    disconnected: 'Disconnected',
    deviceOffline: 'Connection Timeout',
    howToUse: 'How To Connect Whatsapp',
    howToUseConnect: 'How To Use',
    disclaimer:
      '<i><span class="text-subtitle-2">*Before <span class="primary--text font-weight-bold">connecting Whatsapp account</span> to SuperWA, <span class="primary--text font-weight-bold">make sure</span> your account has <span class="primary--text font-weight-bold">previously join multi-device beta and then leave multi-device beta,</span> to <span class="primary--text font-weight-bold"> reduce possibility of disruption</span> from Whatsapp</span></i>',
    howToUseOne: '1. Open <span class="font-weight-bold">Whatsapp</span> on your phone',
    howToUseTwo: '2.  Click <span class="font-weight-bold">3-dots</span> menu on the top right corner',
    howToUseThree: '3. Tap on <span class="font-weight-bold">"Linked Devices"</span>',
    howToUseFour: '4. Scan <span class="font-weight-bold">QR Code</span> beside',
    howToUseFourBelow: '4. Scan <span class="font-weight-bold">QR Code</span> below',
    howToUseOneConnect1: '1. Reply your customer chat in',
    howToUseOneConnect2: 'menu',
    howToUseTwoConnect1: '2. Go to',
    howToUseTwoConnect2: 'menu and select or add the target contacts',
    howToUseThreeConnect1: '3. Type your message or use template from',
    howToUseThreeConnect2: 'menu',
    howToUseFourConnect1: '4. Check the sending history for your broadcast activity in',
    howToUseFourConnect2: 'menu',

    // howToUseOne:
    //   '1. Scan QR code using your Whatsapp app. If you already scaned it, please select the scanned phone number',
    // howToUseTwo: '2. Go to <span class="font-weight-bold">Broadcast</span> menu and select or add the target contacts',
    // howToUseThree:
    //   '3. Type your message or use template from <span class="font-weight-bold">Message Template</span> menu',
    // howToUseFour:
    //   '4. Check the sending history for your broadcast activity in <span class="font-weight-bold">History</span> menu',
  },
  workspaces: {
    editHeader: 'Edit Workspace Name',
  },
  inbox: {
    uploading: 'Uploading...',
    noteMessage: 'Add to notes',
    selectMessages: 'Select messages',
    attachment: 'Attachment',
    typingPlaceholder: 'Type a message',
    newChat: 'New Chat',
    enterPhoneNumber: 'Enter Phone Number',
    or: 'or',
    quickReplies: 'Quick Replies',
    selectFromExistingContact: 'Select From Existing Contact',
    lastInteractWith: 'Last interact with ',
    assignedTo: 'Assigned to ',
    exportChat: 'Export Chat',
    exportMessages: 'Export messages',
    labelMessages: 'Label messages',
    infiniteLoadingErrorMessage: 'Cannot fetch messages',
    infoPhone: 'Keep your phone connected',
    infoPhoneSub:
      'WhatsApp Web connects to your phone to sync messages. To reduce data usage, connect your phone to Wi-Fi.',
    contactEmpty: 'No contacts',
    noChatSelected: 'No contact selected',
    chatEmpty: 'No messages',
    archiveContact: 'Archive this contact',
    unarchiveContact: 'Unarchive this contact',
    addedLabelContact: 'All labels have been added to this contact',
    addedLabelMessage: 'All labels have been added to this message',
  },
  importContactPreview: {
    emptyFields: 'Please select column by field [name and phone number]',
  },
  history: {
    headerMarkupTable: '5 Chat History',
    operators: 'Operators',
    statistics: 'Statistics',
    replies: 'Replies History',
    customerServed: 'Customer Served',
    totalReply: 'Total Reply',
    avgReply: 'Average Reply (Sec)',
    viewAssignOnly: 'View Assign Only',
    autoAssign: 'Auto Assign',
    viewAssignOnly: 'Restrict Access to Only Assigned Contact',
    channelList: 'Enabled Channel List',
    limitedChannel: 'Restrict Access to Only Selected Channel',
    enabledMenu: 'Enabled Menu',
    limitedMenu: 'Restrict Access to Only Selected Menu',
    preparing: 'Preparing',
    chatHistoryData: 'No Conversation Have Done',
    attachments: 'Attachments',
    attachmentsData: 'No Attachment Found',
    to: 'To',
    replyTime: 'Reply Time',
    role: 'Role',
  },
  messageTemplates: {
    templateName: 'Template Name',
    message: 'Message',
    category: 'Category',
    lastUpdate: 'Last Update',

    // openingChat: 'Opening Chat',
    openingChat: 'Message Content',
    onReply: 'On Reply',
    fromTemplates: 'From Templates',
  },
  broadcasts: {
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Broadcast List',
      here: 'Here',
    },
    templateHero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Message Template',
      here: 'Here',
    },
    notesSendingBroadcasts:
      '* Please make sure your <span class="primary--text">channel is connected</span> while the <span class="primary--text">broadcast is being sent</span>',
    loginExpired: 'Login Expired, redirect to Login Page',
    sendingSpeed: 'Sending Speed',
    stepOne: 'New Broadcast - Step 1: Select/Add Message Template',
    stepTwo: 'New Broadcast - Step 2: Select/Upload List of Contacts',
    stepThree: 'New Broadcast - Step 3: Review The Broadcast Plan',
    broadcastName: 'Broadcast Name',
    broadcastList: 'Broadcast List',
    broadcastTime: 'Broadcast Time',
    broadcastScheduled: 'Broadcast Schedule',
    broadcastFrom: 'Broadcast from my contacts',
    broadcastFromInfo: 'Download sample format for contact upload',
    broadcastFromExcel: 'Broadcast from imported excel file',
    broadcastFromExcelInfo: 'Select file to upload list of receivers',
    channel: 'Channel',
    messageTemplate: 'Message Template',
    executionType: 'Execution Type',
    immediately: 'Immediately',
    scheduled: 'Scheduled',
    date: 'Date',
    time: 'Time',
    numberContacts: 'Number of Contacts',
    executionDatetime: 'Execution Date/Time',
    totalContacts: 'Total Contacts',
    recipients: 'Recipients',
    processing: 'Processing',
    queued: 'Queued',
    sending: 'Sending',
    failed: 'Failed',
    sent: 'Sent',
    delivered: 'Delivered',
    read: 'Read',
    replied: 'Replied',
    received: 'Received',
    unsend: 'Unsend',
    unknown: 'Unknown',
    statistics: 'Statistics',
    totalBroadcasts: 'Total Broadcast',
    broadcastStatistic: 'Broadcast Statistic',
    linkClicked: 'Link Clicked',
    clickedAt: 'Clicked At',
    today: 'Today',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    four_day: '4 Days',
    mustFilled: 'Must be filled',
    alertSelectContact: 'Please Select Minimal 1 Contact',
    stepOneSubtitle: 'Select Contact for Broadcast',
    stepOneTitle: 'Channel & Contact',
    stepTwoSubtitle: 'Select Message for Broadcast',
    stepTwoTitle: 'Message Templates',
    stepThreeSubtitle: 'Check Broadcast Data',
    stepThreeTitle: 'Review & Submit',
    selected: 'Selected',
    search: 'Search',
    chooseTemplateFirst: 'Choose Template First',
    custom: 'Custom',
    datetime: 'Custom',
    tonight7pm: 'Afternoon at 7PM',
    today10am: 'Morning at 10AM',
    today08am: 'Morning at 8AM',
    noon01pm: 'Noon at 1PM',
    createBroadcast: 'Create Broadcast',
    editBroadcast: 'Edit Broadcast',
    sendPreview: 'Send Preview',
    saveDraft: 'Save As Draft',
    send: 'Send',
    customMessage: 'Custom Message',
    noLink: 'No Available Link',
    linkOff: 'Off',
    linkOn: 'On',
    getContacts: 'Getting Contacts',
    customDelay: 'Set Custom Delay',
    delayInterval: 'Delay Interval',
    seconds: 'Seconds',
    delayFrom: 'From',
    delayTo: 'To',
    restMode: 'Rest Mode',
    additionalDelay: 'For (Seconds)',
    stopAfter: 'Stop Sending After (Messages) ',
    pleaseSelect: 'Please Select',
    contactSource: 'Contact Source',
    chooseFrom: 'Choose Manually from',
    customFrom: 'Custom from',
    otherList: 'Other List',
    thereAre: 'There are',
    duplicatePhone: 'Duplicate Phone Number',
    unsubscribePhone: 'Unsubscribe Phone Number',
    confirmDelete: 'Are you sure want to Delete',
    confirm: 'Confirmation',
    notAllField: 'Not All Field',
    inOpeningChat: 'in "Opening Chat" message, available in',
    onReplyChat: 'in "On Reply" message, available in',
    contactListSelected: 'Contact List Selected',
    allChannelDisconnected: 'All channel Disconnected',
    checkChannels: 'Checking Channels Status',
    uploadNotes: 'Upload Image Only',
    deleteFailedNotCreated: 'Cannot be deleted, a broadcast message is being created',
  },
  contacts: {
    contact: 'Contact',
    contactList: 'Contact List',
    createContactGroupList: 'Create Contact List',
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Contact List',
      here: 'Here',
    },
    importContact: 'Import Contacts',
    importContactSub: 'Import your contacts from different sources',
    contactName: 'Contact Name',
    name: 'Name',
    phoneNumber: 'Phone Number',
    source: 'Source',
    profilePicture: 'Profile Picture',
    importContact: 'Import Contact',
    currentSetting: 'Current Setting',
    currentSettingName: 'Setting Name',
    attribute: 'Attribute',
    listManage: 'List Manage',
    listName: 'List Name',
    lastModified: 'Last Modified',
    showChat: 'Show Chat',
    newLabel: 'New Label',
    color: 'Color',
    value: 'Value',
    field: 'Field',
    action: 'Action',
    config: 'Config',
    columnName: 'Column Name',
    defaultValue: 'Default Value',
    showField: 'Show Field',
    required: 'Required',
    chooseCsv: 'Choose CSV File',
    chooseVcf: 'Choose VCF File',
    scanQr: 'Scan if you want to import from new number',
    hintSheet: 'Make sure your spreadsheet setting is share with anyone with this link',
    deleteSelectedContact: 'Delete Selected Contact',
    changeSelectedContact: 'Change Selected Contact',
    broadcastSelectedContact: 'Broadcast Selected Contact',
    setLabelSelectedContact: 'Set Label Selected Contact',
    deleteSelectedSetting: 'Delete Selected Setting',
    deleteSelectedLabel: 'Delete Selected Label',
    columnNameRequired: 'Column Name is Required',
    contactsNameRequired: 'Contact Name is Required',
    phoneNumberRequired: 'Phone Number is Required',
    phoneNumberNotValid: 'Phone Number is Not Valid',
    phoneNumberExist: 'Phone Number is Exist',
  },
  subscriptions: {
    subscriptionPlan: 'Subscription Plan',
    monthly: 'Monthly',
    annually: 'Annually',
    package: 'Package',
    coupon: 'Coupon',
    action: 'Action',
    extra: 'Extra',
    maxOperators: 'Max Operators',
    maxChannels: 'Max Channels',
    invoiceHistory: 'Invoice History',
    currentPlan: 'Current Plan',
    ExpiredAt: 'Expired at',
    CreatedAt: 'Created At',
    TotalPrice: 'Total Price',
    PayWith: 'Pay With',
    yourCurrentPersonalWorkspacePlan: 'Your Current Personal Workspace Plan',
    detail:
      'All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.',
  },
  widget: {
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Widget',
      here: 'Here',
    },
    showWidget: 'SHOW WIDGET',
    lastModified: 'LAST MODIFIED',
  },
  LinkGenerator: {
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Link Generator',
      here: 'Here',
    },
    trackerHero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Link Tracker',
      here: 'Here',
    },
    createNewLink: 'Create New Link',
    slugIsAvailability: 'Slug is available',
    slugIsNotAvailability: 'Slug is not available',
    copySuccess: 'Copied Successfully',
    copyUnsupported: 'Copy is not supported in this device/browser.',
    settings: 'Settings',
    alertSlug: 'Please Check slug Availability',
    alertChannel: 'Please Select the Channel',
    confirmDeleteLink: 'Do you want delete this Link Generate?',
    tutorialSlugOne: 'Slug link is used to differentiate directed link from other user',
    tutorialSlugTwo: 'You can specify the message that the user will sent to You',
    tutorialSlugThree: `You can determine how many Operators that will received messages from the link that's clicked by the user`,
    tutorialSlugFour: 'You have Google Tag ? Paste here to track your Google Tag Manager',
    tutorialSlugFive:
      'You can also set loading when user clicked the link that you made to maximize Google Tag Manager',
  },
  EOrdering: {
    createShop: 'Create Shop',
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Widget',
      here: 'Here',
    },
    createNewLink: 'Create New Link',
    slugIsAvailability: 'slug is available',
    slugIsNotAvailability: 'slug is not available',
    copySuccess: 'Copied Successfully',
    copyUnsupported: 'Copy is not supported in this device/browser.',
    settings: 'Settings',
    alertSlug: 'Please Check slug Availability',
    alertChannel: 'Please Select the Channel',
    confirmDeleteLink: 'Do you want delete this Link Generate?',
    tutorialAddress: 'Write address details clearly',
    tutorialName: 'Create a unique shop name',
    tutorialSlugTwo: 'You can specify the message that the user will sent to You',
    tutorialSlugThree: `You can determine how many Operators that will received messages from the link that's clicked by the user`,
    tutorialSlugFour: 'You have Google Tag ? Paste here to track your Google Tag Manager',
    tutorialSlugFive:
      'You can also set loading when user clicked the link that you made to maximize Google Tag Manager',
  },
  OperatorsTranslate: {
    confirmWant: 'Do you want delete',
    meaningAction: 'as your Operator?',
  },
  coupons: {
    coupon: 'Coupon',
  },
  landing: {
    hero: {
      sendManyMsg: 'Send Many WhatsApp Messages With Only',
      oneClick: 'One Click',
      increaseSales: 'Increase Your Sales Up to 300% by directly interacting with your customer using WhatsApp',
      tryNow: 'Try Now',
      sendMessage: 'Send Message',
    },
    howItWork: {
      title: 'How It Work',
      headline: 'How Does It Work ?',
      stepOne: {
        title: '1. Connect Your WhatsApp Number',
        detail: 'Scan the given QR Code that we have provided, after that, You will be connected to our server.',
      },
      stepTwo: {
        title: '2. Select The Receivers Of Your Broadcast',
        detail: 'Please select the contacts that You want to receive the message.',
      },
      stepThree: {
        title: '3. Automated Message Sending Started',
        detail: 'If finished, let our system do the work. You can sit tight, and drink some coffee.',
      },
    },
    features: {
      title: 'Features',
      headline: 'What are the advanced features in SuperWA ?',
      description:
        'SuperWA is a web based tool for you as an online entrepreneur that can help increase Your sales become far more effective & efficient',
      featureOne: {
        title: 'Send Unlimited Messages',
        body: 'Send your message to hundred or thousand customers every day without limit.',
      },
      featureTwo: {
        title: 'Multiple User WhatsApp Web',
        body: 'One access for your WhatsApp web with multiple users to facilitate serving the customer efficiently,',
      },
      featureThree: {
        title: 'Fully Customizable Message',
        body: `Make Your messages dynamic by adding the customer's name and emoji.`,
      },
      featureFour: {
        title: 'Attach Image on Broadcast Message',
        body: 'Make Your messages more entrancing by attaching pictures.',
      },
      featureFive: {
        title: 'Custom Broadcast Scheduling',
        body: `Planning your broadcast with simple steps. Broadcast your message whenever you need.`,
      },
      featureSix: {
        title: 'Sync Contacts from WhatsApp',
        body: 'With just one click, you can import or export all your contacts from WhatsApp.',
      },
      featureSeven: {
        title: 'Label your customer',
        body: `Group your customer based on a label you’ve created.`,
      },
      featureEight: {
        title: 'Import & Export Contacts',
        body: 'Manually or automatically input contacts with only one click.',
      },
      featureNine: {
        title: 'Track Your Team Performance',
        body: `Track Your team's performance and reply speed daily, weekly, and monthly.`,
      },
      featureTen: {
        title: 'Message Labeling',
        body: 'Label chats for Everytime You process Your order',
      },
    },
    demo: {
      title: 'Demo',
      headline: 'SuperWA Demo',
      subTitle: 'Scan by WA and send your first message',
      subTitlesuccess: 'Your first message is send successfully, try our app to send broadcast and more',
    },
    testimonials: {
      title: 'Testimonials',
      headline: 'Testimonial',
      testimonialOneDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testimonialTwoDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    demoLanding: {
      stepOne: {
        android: { open: 'Open', desc: 'on Your phone', moreOpt: `Tap 'More Options'` },
        ios: {
          open: 'Open',
          desc: 'on Your Phone',
          moreOpt: 'Go to WhatsApp',
          moreOpt2: 'Setting',
        },
      },
      stepTwo: {
        android: {
          tap: 'Tap',
          linkDevice: 'LINK A DEVICE',
          desc: 'Follow the on-screen instructions if Your device has biometric authentication. If You don’t have biometric authentication enabled, You’ll be prompted to enter the pin You use to unlock Your phone.',
        },
        ios: {
          desc: `On iOS 14 and above, use Touch ID or Face ID to unlock. If you don’t have biometric authentication enabled, you’ll be prompted to enter the pin you use to unlock your phone.`,
        },
      },
      stepThree: {
        android: {
          select: 'Select',
          keepSigned: 'Keep Me Signed In',
          desc: {
            head: 'Select the checkbox next to',
            body: 'on the QR screen on your computer or Portal to stay logged in on this device.',
          },
        },
      },
      stepFour: {
        android: {
          scan: 'Scan',
          qrCode: 'QR Code',
          below: 'below',
        },
      },
      note: {
        headline: 'NOTE:',
        body: `"If You can't scan the QR Code, Make sure that You're not using WhatsApp's multi device beta, You can see the instruction to leave the beta`,
        link: `here`,
      },
    },
    faq: {
      title: 'FAQ',
      subTitle: 'Let us help answer the most common questions.',
      faqOne: {
        question: 'What is SuperWA ?',
        answer:
          'SuperWA is a tool that can send multiple messages with only one click, there are also a lot of features in SuperWA like Group Contacts Grabbar, Live chat Widget, WA Link Rotator, etc.',
      },
      faqTwo: {
        question: 'Do I really need SuperWA ?',
        answer: `You are entitled to make your own decision. Like let's say, letting yourself be left behind by other rival businesses. It's ok`,
      },
      faqThree: {
        question: 'Is SuperWA suited for me ?',
        answer:
          'SuperWA is suited for marketers from various circles (Online shop owners, Digital Advertiser, Blogger, Affiliate Marketer, etc) that uses WhatsApp as a means for promotion.',
      },
      faqFour: {
        question: 'Is there already an existing WhatsApp number database ?',
        answer: `No there isn't. SuperWA is not a platform that provides WhatsApp numbers for sending promotions. all of the WhatsApp numbers have to come from you (The person doing the buisiness), and the number has to be obatained legally.`,
      },
      faqFive: {
        question: 'Does SuperWA need to be installed on the computer ?',
        answer: `No need. SuperWA is a web based tool, that can be accessed VIA any platform (Desktop/Mobile) or (Windows, Mac OS, Ubuntu, Android, iOS, etc). As long as the device you're using has a browser, then SuperWA will always accessible.`,
      },
      faqSix: {
        question: `I don't understand technology, Is there a user manual? ?`,
        answer: `Very complete. In the member area, you can select the "User Manual", there is a video on how to use SuperWA that you can learn. Still confused? Don't worry. Our team is ready to answer all of your questions.`,
      },
      faqSeven: {
        question: 'Is there a money back warranty / refund ?',
        answer: `NO THERE ISN'T`,
      },
      faqEight: {
        question: 'If my number gets blocked, Can I get a refund ?',
        answer: `There are no third-party tool that's 100% safe from being blocked by WhatsApp. SuperWA has over 1.000 daily active users. If you get blocked by WhatsApp, You cannot Refund.`,
      },
    },
  },
  eform: {
    add: 'Starting a new form',
    notFound: 'No form yet',
    notFoundAction: 'Click + to create a new form.',
    title: 'Form Title',
    titlePlaceholder: 'Untitled form',
    titleEmpty: 'Title is required.',
    description: 'Form Description',
    descriptionPlaceholder: 'Form description',
    discard: 'Discard this form?',
    notFoundById: 'eForm not found',
    copyUrlSurvey: 'Copy survey link',
    openSurvey: 'Open survey',
    surveyAnalytics: 'Survey analytics',
    savePdfSurvey: 'Save PDF survey',
  },
}
