import '@/plugins/axios'
import '@/plugins/axios-limit'
import { i18n } from '@/plugins/i18n'
import '@/plugins/moment'
import '@/plugins/nanoid'
import '@/plugins/vue-composition-api'
import '@/plugins/vuex'
import '@/styles/styles.scss'
import VueCompositionApi from '@vue/composition-api'
import Vue from 'vue'
import VueHead from 'vue-head'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueHead)
Vue.use(VueCompositionApi)
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
})

window.$ = window.jQuery = require('jquery')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
