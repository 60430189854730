import axios from 'axios'
import Vue from 'vue'

Vue.prototype.$axios = axios

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function(config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function(response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function(error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

Vue.prototype.$axiosLimit = conn

export default conn
