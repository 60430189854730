export default {
  // menu

  needMoreHelpTextIg: 'Kunjungi instagram kita',
  needMoreHelpTextFb: 'Lihat kita di facebook',
  needMoreHelpTextEmail: 'Cara terbaik & tercepat mendapat jawaban ',
  needMoreHelpText: 'hubungi kami dengan kontak di bawah, kami akan membantu kamu secepat yang kami bisa',
  needMoreHelp: 'Perlu Bantuan?',
  HelpCenter: 'Pusat Bantuan',
  contactUs: 'Hubungi Kami',
  eOrdering: 'eOrdering',
  eForm: 'eForm',
  member: 'Anggota',
  price: 'Harga',
  startConversation: 'Mulai Percakapan',
  customer: 'Pelanggan',
  notes: 'Catatan',
  lastMessage: 'Pesan Terakhir',
  confirmDelete: 'Anda yakin ingin menghapus?',
  deleteTemplate: 'Anda yakin ingin menghapus template ini?',
  bulkDeleteTemplate: 'Anda yakin ingin menghapus semua template yang dipilih?',
  messageTemplatesRead: 'Templates Pesan',
  Channels: 'Channels',
  Inbox: 'Inbox',
  Broadcast: 'Broadcast',
  'Broadcast List': 'Daftar Broadcast',
  'Scheduled Broadcast': 'Jadwal Broadcast',
  'List Broadcast': 'Daftar Broadcast',
  'Broadcast Schedule': 'Jadwal Broadcast',
  Statistic: 'Statistic',
  Contact: 'Kontak',
  Contacts: 'Contacts',
  Label: 'Label',
  Widget: 'Widget',
  Template: 'Template',
  Operators: 'Operator',
  'Operators List': 'Daftar Operator',
  'Replies History': 'Riwayat Balasan',
  'Operator Statistic': 'Statistik Operator',
  Subscriptions: 'Langganan',
  'Link Generator': 'Link Generator',
  Chat: 'Chat',
  'Chat Data': 'Chat Data',
  'Live Chat Widget': 'Live Chat Widget',
  'Contact List': 'Contact List',
  contactGroup: 'Grup Kontak',
  Tutorial: 'Cara Penggunaan',
  'Help Center': 'Pusat Bantuan',
  'Contact Us': 'Hubungi Kami',
  'Business+': 'Business+',
  'Link Tracker': 'Link Tracker',

  // key
  freeTrialRemaining: 'Sisa waktu uji coba:',
  demoMessageSuccess: 'Yeay, pesan kamu sudah terkirim!',
  update: 'Perbarui',
  channel: 'channel',
  refresh: 'Refresh',
  message: 'pesan',
  List: 'Daftar',
  Import: 'Impor',
  scan: 'memindai',
  Fields: 'Fields',
  field: 'field',
  value: 'value',
  setLabel: 'Tetapkan Label',
  config: 'konfigurasi',
  logout: 'Keluar',
  ok: 'OK',
  new: 'Baru',
  create: 'Buat',
  add: 'Tambah',
  addTo: 'Tambah Ke',
  edit: 'Ubah',
  cancel: 'Batal',
  save: 'Simpan',
  warning: 'Peringatan',
  delete: 'Delete',
  deleteConfirmation: 'Apakah Anda yakin ingin menghapus',
  archive: 'Arsip',
  unarchived: 'Kembalikan Arsip',
  archivedConfirmation: 'Apakah Anda yakin ingin mengarsipkan',
  unarchivedConfirmation: 'Apakah Anda yakin ingin mengembalikan arsip',
  deleteMessage: 'Apakah Anda yakin ingin menghapus data ini?',
  syncfromserver: 'Sinkronasi data dari server',
  search: 'Cari',
  select: 'Pilih',
  selectall: 'Pilih Semua',
  send: 'Kirim',
  submit: 'Kirim',
  next: 'Lanjut',
  previous: 'Sebelumnya',
  finish: 'Selesai',
  close: 'Tutup',
  from: 'Dari',
  clear: 'Bersihkan',
  rowsPerPageLabel: 'Data per halaman:',
  noDataLabel: 'Tidak ada data yang tersedia',
  loadingLabel: 'Memuat data...',
  phoneNumber: 'Nomor ponsel',
  notFound: 'tidak ditemukan',
  noResults: 'Tidak ada hasil',
  view: 'Lihat',
  preview: 'Pratinjau',
  tryAgain: 'Coba Lagi',
  column: 'Kolom',
  source: 'Sumber',
  lastReply: 'Terakhir Kali Membalas',
  favourite: 'Favorit',
  interaction: 'Interaksi',
  needReply: 'Butuh Dibalas',
  reply: 'Balas',
  assignTo: 'Pasangkan Ke',
  messageStatus: 'Status Pesan',
  name: 'Nama',
  action: 'Aksi',
  continuousSync: 'Sinkronisasi Berkelanjutan',
  continuousSyncInfo: 'Terus sinkronkan kontak dengan sheet ini setiap 5 menit',
  sheetName: 'Nama Sheet',
  record: 'data',
  selectedOf: 'dipilih dari',
  you: 'Anda',
  or: 'atau',
  import: 'Impor',
  link: 'Tautan',
  total: 'Total',
  exportToCsv: 'Ekspor ke CSV',
  export: 'Ekspor',
  changeSelectedData: 'Ubah Data yang Dipilih',
  changeLabel: 'Ganti Label',
  change: 'Ganti',
  deleteSelectedData: 'Hapus Data yang Dipilih',
  sendyourfirstmessage: 'Kirim pesan pertama anda',
  warningfailedsync: 'Gagal sinkronisasi refresh untuk mengulang',
  warningactiveinvoice: 'Anda memiliki tagihan Xendit yang belum dibayar',
  warningpackageexpired: 'Workspace anda sudah expire',
  warningmaxchannel: 'Anda mencapai batas maksimal jumlah Channel, tingkatkan akun atau tambah ekstra Channel',
  warningmaxoperator: 'Anda mencapai batas maksimal jumlah Operator, tingkatkan akun atau tambah ekstra Operator',
  warningmaxcontacts: 'Anda mencapai batas maksimal jumlah Contacts, tingkatkan akun atau tambah ekstra Contacts',
  warningfailedsavedata: 'Gagal menyimpan data, mohon periksa koneksi anda',
  pay: 'Bayar',
  year: 'Tahun',
  years: 'tahun',
  month: 'Bulan',
  monthsInterval: 'Bulan',
  months: 'bulan',
  day: 'hari',
  days: 'hari',
  min: 'menit',
  mins: 'menit',
  hour: 'jam',
  hours: 'jam',
  second: 'detik',
  seconds: 'detik',
  chat: 'Pesan',
  serverConnectionLost: 'Koneksi Ke Server Terputus',
  dialog: {
    invalidFile: 'Harap pilih file yang benar dan coba lagi',
    requiredMessage: 'Pesan diperlukan',
    channelRequired: 'Harap pilih channel yang akan diimport',
    mappingallfield: 'harap mapping kolom Name dan Phone Number',
    failedgetdata: 'Gagal mengambil data',
    notvalidsheet: 'Spreadsheet URL tidak Valid',
    pleaseselectone: 'Harap pilih kontak',
    isRequired: ' wajib diisi',
    errorSendMessage: 'Tidak dapat mengirim pesan, silakan coba lagi',
    errorUploadAttachment: 'Tidak dapat mengirim lampiran, silakan coba lagi',
    selectChannel: 'Mohon pilih Channel',
    selectGroupContact: 'Mohon Pilih Grup Kontak',
    errorImportUser: 'Terjadi Kesalahan Unduh Data User Group',
  },
  EOrdering: {
    createShop: 'Buat Toko',
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Membuat Toko',
      here: 'Disini',
    },
  },
  channels: {
    channel: 'Channel',
    label: 'Label',
    color: 'Warna',
    phoneNumber: 'Nomor Telepon',
    actions: 'Aksi',
    backgroundMessage: 'Pesan Latar Belakang Aktif',
    infoBackgroundMessage:
      'Untuk menghindari deteksi spam Whatsapp, Anda dapat mengaktifkan fitur ini untuk menambah jumlah obrolan masuk',
    syncSetting: 'Pengaturan Sinkronisasi',
    syncSettingOptionOne: 'Semua Pesan',
    syncSettingOptionTwo: 'Hanya Dari Kontak yang Diketahui',
    syncContact: 'Sinkronisasi Kontak',
    muteNotification: 'Mute Notifikasi',
    boldUnreplied: 'Mode Tersembunyi',
    infoStealthMode: 'Hanya menandai chat terbaca jika membalas chat',
    connected: 'Terhubung',
    notConnected: 'Tidak Terhubung',
    disconnected: 'Terputus',
    deviceOffline: 'Koneksi Terputus',
    howToUse: 'Cara Penggunaan',
    howToUseConnect: 'Cara Penggunaan',
    disclaimer:
      '<i><span class="text-subtitle-2">*Sebelum <span class="primary--text font-weight-bold">menghubungkan akun Whatsapp</span> ke SuperWA, <span class="primary--text font-weight-bold">pastikan</span> akun Anda sebelumnya telah <span class="primary--text font-weight-bold">bergabung dengan multi-device beta kemudian keluar dari multi-device beta,</span> untuk mengurangi <span class="primary--text font-weight-bold">kemungkinan gangguan </span> dari Whatsapp</span></i>',
    howToUseOne: '1. Buka <span class="font-weight-bold">Whatsapp</span> pada handphone anda',
    howToUseTwo: '2.  Klik <span class="font-weight-bold">3-titik</span> pada menu di kanan atas',
    howToUseThree: '3. Tap pada <span class="font-weight-bold">"Linked Devices"</span>',
    howToUseFour: '4. Scan <span class="font-weight-bold">QR Code</span> disamping',
    howToUseFourBelow: '4. Scan <span class="font-weight-bold">QR Code</span> dibawah',
    howToUseOneConnect1: '1. Balas pesan pelanggan anda pada menu',
    howToUseTwoConnect1: '2. Buka menu',
    howToUseThreeConnect1: '3. Ketik pesan Anda atau gunakan template dari menu',
    howToUseFourConnect1: '4. Periksa riwayat pengiriman untuk aktivitas Broadcast Anda di menu',
    howToUseOneConnect2: '',
    howToUseTwoConnect2: 'dan pilih atau tambahkan kontak target',
    howToUseThreeConnect2: '',
    howToUseFourConnect2: '',

    // howToUseOne:
    //   '1. Pindai kode QR menggunakan aplikasi Whatsapp Anda. Jika Anda sudah memindainya, silakan pilih nomor telepon yang dipindai',
    // howToUseTwo: '2. Buka menu <span class="font-weight-bold">Broadcast</span> dan pilih atau tambahkan kontak target',
    // howToUseThree:
    //   '3. Ketik pesan Anda atau gunakan template dari menu <span class="font-weight-bold">Message Template</span>',
    // howToUseFour:
    //   '4. Periksa riwayat pengiriman untuk aktivitas Broadcast Anda di menu <span class="font-weight-bold">History</span>',
  },
  workspaces: {
    editHeader: 'Ubah Nama Workspace',
  },
  inbox: {
    uploading: 'Mengunggah...',
    noteMessage: 'Tambah ke catatan',
    selectMessages: 'Pilih pesan',
    attachment: 'Lampiran',
    typingPlaceholder: 'Tulis pesan',
    newChat: 'Percakapan Baru',
    enterPhoneNumber: 'Masukkan Nomor Ponsel',
    or: 'atau',
    quickReplies: 'Balas Cepat',
    selectFromExistingContact: 'Pilih Dari Kontak yang Ada',
    lastInteractWith: 'Berinteraksi terakhir dengan ',
    assignedTo: 'Ditugaskan untuk ',
    exportChat: 'Ekspor Percakapan',
    exportMessages: 'Ekspor pesan',
    labelMessages: 'Label pesan',
    infiniteLoadingErrorMessage: 'Tidak dapat memuat pesan',
    infoPhone: 'Pastikan ponsel Anda tetap terhubung',
    infoPhoneSub:
      'WhatsApp Web terhubung ke ponsel Anda untuk menyinkronkan pesan. Untuk mengurangi penggunaan data, sambungkan ponsel Anda ke Wi-Fi.',
    contactEmpty: 'Tidak ada kontak',
    noChatSelected: 'Tidak ada kontak yang dipilih',
    chatEmpty: 'Tidak ada pesan',
    archiveContact: 'Arsipkan kontak ini',
    unarchiveContact: 'Batal arsip kontak ini',
    addedLabelContact: 'Semua label sudah ditambahkan pada kontak ini',
    addedLabelMessage: 'Semua label sudah ditambahkan pada pesan ini',
  },
  importContactPreview: {
    emptyFields: 'Harap pilih kolom dari field [name dan phone number]',
  },
  history: {
    headerMarkupTable: '5 Riwayat Percakapan',
    operators: 'Operator',
    statistics: 'Statistik',
    replies: 'Riwayat Balasan',
    customerServed: 'Pelanggan Dilayani',
    totalReply: 'Total Balasan',
    avgReply: 'Rata-rata Balasan (Detik)',
    viewAssignOnly: 'Tampilkan Hanya yang Ditugaskan',
    autoAssign: 'Menugaskan Otomatis',
    viewAssignOnly: 'Akses Terbatas untuk Contact yang Diberikan',
    channelList: 'List Channel Aktif',
    limitedChannel: 'Akses Terbatas untuk Channel yang Diberikan',
    enabledMenu: 'Menu Aktif',
    limitedMenu: 'Akses Terbatas untuk Menu yang Diberikan',
    preparing: 'Mempersiapkan',
    chatHistoryData: 'Tidak ada percakapan yang telah dilakukan',
    attachments: 'Lampiran',
    attachmentsData: 'Tidak ada ditemukan lampiran',
    to: 'Kepada',
    replyTime: 'Waktu Balasan',
    role: 'Peran',
  },
  messageTemplates: {
    templateName: 'Nama Template',
    message: 'Pesan',
    category: 'Kategori',
    lastUpdate: 'Terakhir Kali di Perbarui',

    // openingChat: 'Pesan Pembuka',
    openingChat: 'Isi Pesan',
    onReply: 'Pesan Balasan',
    fromTemplates: 'Dari Template',
  },
  broadcasts: {
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Daftar Broadcast',
      here: 'Disini',
    },
    templateHero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Template Pesan',
      here: 'Disini',
    },
    notesSendingBroadcasts:
      '* Mohon pastikan <span class="primary--text">channel anda terkoneksi</span> saat <span class="primary--text">broadcast sedang dikirim</span>',
    loginExpired: 'Sesi Login Telah Habis, Dialihkan Ke Login Page',
    sendingSpeed: 'Kecepatan Pengiriman',
    stepOne: 'Broadcast Baru - Langkah 1: Pilih atau Tambah Message Template',
    stepTwo: 'Broadcast Baru - Langkah 2: Pilih Dari atau Upload Daftar Kontak',
    stepThree: 'Broadcast Baru - Langkah 3: Tinjauan Broadcast',
    broadcastName: 'Nama Broadcast',
    broadcastList: 'Daftar Broadcast',
    broadcastTime: 'Waktu Broadcast',
    broadcastScheduled: 'Jadwal Broadcast',
    broadcastFrom: 'Broadcast dari kontak Anda',
    broadcastFromInfo: 'Unduh contoh format untuk mengunggah kontak',
    broadcastFromExcel: 'Broadcast dari file excel yang diimpor',
    broadcastFromExcelInfo: 'Pilih file untuk mengunggah daftar penerima',
    channel: 'Pengirim',
    messageTemplate: 'Template Pesan',
    executionType: 'Tipe Eksekusi',
    immediately: 'Langsung',
    scheduled: 'Terjadwal',
    date: 'Tanggal',
    time: 'Waktu',
    numberContacts: 'Jumlah Kontak',
    executionDatetime: 'Tanggal/Waktu Eksekusi',
    totalContacts: 'Total Kontak',
    recipients: 'Penerima',
    processing: 'Memproses',
    queued: 'Antrian',
    sending: 'Mengirim',
    failed: 'Gagal',
    sent: 'Terkirim',
    delivered: 'Terkirim',
    read: 'Dibaca',
    replied: 'Dibalas',
    received: 'Diterima',
    unsend: 'Batal Kirim',
    unknown: 'Tidak Diketahui',
    statistics: 'Statistik',
    totalBroadcasts: 'Jumlah Broadcast',
    broadcastStatistic: 'Broadcast Statistik',
    linkClicked: 'Tautan Diklik',
    clickedAt: 'Diklik pada',
    today: 'Hari ini',
    day: 'Hari',
    week: 'Minggu',
    month: 'Bulan',
    four_day: '4 Hari',
    mustFilled: 'Harus di Isi',
    alertSelectContact: 'Mohon Pilih Minimal 1 Kontak',
    stepOneSubtitle: 'Pilih Kontak untuk Broadcast',
    stepOneTitle: 'Pengirim & Kontak',
    stepTwoSubtitle: 'Pilih Pesan untuk Broadcast',
    stepTwoTitle: 'Template Pesan',
    stepThreeSubtitle: 'Cek Data Broadcast',
    stepThreeTitle: 'Tinjau & Kirim',
    selected: 'Dipilih',
    search: 'Cari',
    chooseTemplateFirst: 'Choose Template First',
    custom: 'Custom',
    datetime: 'Custom',
    tonight7pm: 'Malam Jam 7',
    today10am: 'Pagi Jam 10',
    today08am: 'Pagi Jam 8',
    noon01pm: 'Siang Jam 1',
    createBroadcast: 'Membuat Broadcast',
    editBroadcast: 'Mengubah Broadcast',
    sendPreview: 'Kirim Pratinjau',
    saveDraft: 'Simpan Draft',
    send: 'Kirim',
    customMessage: 'Pesan Kustom',
    noLink: 'Tidak Ada Link Tersedia',
    linkOff: 'Mati',
    linkOn: 'Hidup',
    getContacts: 'Memuat Kontak',
    customDelay: 'Atur Penundaan Pengiriman',
    delayInterval: 'Jarak Penundaan',
    seconds: 'Detik',
    delayFrom: 'Dari',
    delayTo: 'Hingga',
    restMode: 'Mode Istirahat',
    additionalDelay: 'Selama (Detik)',
    stopAfter: 'Berhenti Kirim Setelah (Pesan) ',
    pleaseSelect: 'Mohon Pilih',
    contactSource: 'Sumber Kontak',
    chooseFrom: 'Pilih Manual Dari',
    customFrom: 'Kustom Dari',
    otherList: 'Daftar Lainnya',
    thereAre: 'Terdapat',
    duplicatePhone: 'Nomor Telepon Duplikat',
    unsubscribePhone: 'Nomor Telepon yang tidak berlangganan',
    confirmDelete: 'Apakah anda yakin ingin menghapus',
    confirm: 'Konfirmasi',
    notAllField: 'Tidak Semua Field',
    inOpeningChat: 'di Pesan "Opening Chat", tersedia pada',
    onReplyChat: 'di Pesan "On Reply", tersedia pada',
    contactListSelected: 'Daftar Kontak Terpilih',
    allChannelDisconnected: 'Semua Channel Terputus',
    checkChannels: 'Mengecek Status Channel',
    uploadNotes: 'Unggah Hanya File Gambar',
    deleteFailedNotCreated: 'Tidak bisa didelete, pesan broadcast sedang di buat',
  },
  contacts: {
    contact: 'Kontak',
    contactList: 'Daftar Kontak',
    createContactGroupList: 'Buat Daftar Kontak',
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Daftar Kontak',
      here: 'Disini',
    },
    importContact: 'Impor Kontak',
    importContactSub: 'Impor kontak dari berbagai sumber',
    contactName: 'Nama Kontak',
    name: 'Nama',
    phoneNumber: 'Nomor Ponsel',
    source: 'Sumber',
    profilePicture: 'Foto Profil',
    importContact: 'Impor Kontak',
    currentSetting: 'Pengaturan Saat Ini',
    currentSettingName: 'Nama Pengaturan',
    attribute: 'Atribut',
    listManage: 'Kelola Daftar',
    listName: 'Daftar Nama',
    lastModified: 'Terakhir di Ubah',
    showChat: 'Tampilkan Chat',
    newLabel: 'Label Baru',
    color: 'Warna',
    value: 'Nilai',
    field: 'Kolom',
    action: 'Aksi',
    config: 'Konfigurasi',
    columnName: 'Nama Kolom',
    defaultValue: 'Nilai Default',
    showField: 'Tampilkan Kolom',
    required: 'Dibutuhkan',
    chooseCsv: 'Pilih File CSV',
    chooseVcf: 'Pilih File VCF',
    scanQr: 'Pindai jika Anda ingin mengimpor dari nomor baru',
    hintSheet: 'Pastikan setelan spreadsheet Anda dibagikan dengan siapa saja yang memiliki tautan ini',
    deleteSelectedContact: 'Hapus Kontak yang Dipilih',
    changeSelectedContact: 'Ubah Kontak yang Dipilih',
    broadcastSelectedContact: 'Broadcast Kontak yang Dipilih',
    setLabelSelectedContact: 'Atur Label Kontak yang Dipilih',
    deleteSelectedSetting: 'Hapus Pengaturan yang Dipilih',
    deleteSelectedLabel: 'Hapus Label yang Dipilih',
    columnNameRequired: 'Nama kolom wajib diisi',
    contactsNameRequired: 'Nama kontak wajib diisi',
    phoneNumberRequired: 'Nama Telp wajib diisi',
    phoneNumberNotValid: 'Nomor telp tidak valid',
    phoneNumberExist: 'Nomor telp sudah terdaftar',
  },
  subscriptions: {
    subscriptionPlan: 'Paket Langganan',
    maxOperators: 'Maksimal Operator',
    monthly: 'Bulanan',
    annually: 'Tahunan',
    package: 'Paket',
    coupon: 'Kupon',
    action: 'Aksi',
    extra: 'Ekstra',
    maxChannels: 'Maksimal Channel',
    invoiceHistory: 'Riwayat Faktur',
    CreatedAt: 'Dibuat pada',
    currentPlan: 'Plan Saat Ini',
    ExpiredAt: 'Berakhir pada',
    TotalPrice: 'Total Harga',
    PayWith: 'Bayar dengan',
    yourCurrentPersonalWorkspacePlan: 'Plan Workspace Milik Anda Saat Ini',
    detail: 'Semua rencana termasuk 40+ peralatan canggih dan fitur-fitur untuk meningkatkan produk Anda.',
  },
  widget: {
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Widget',
      here: 'Disini',
    },
    showWidget: 'TAMPILKAN WIDGET',
    lastModified: 'TERAKHIR DIUBAH',
  },
  LinkGenerator: {
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Link Generator',
      here: 'Disini',
    },
    trackerHero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Link Tracker',
      here: 'Disini',
    },
    createNewLink: 'Buat Link Baru',
    slugIsAvailability: 'Slug bisa dipakai',
    slugIsNotAvailability: 'Slug tidak bisa dipakai',
    copySuccess: 'Copy Berhasil dilakukan',
    copyUnsupported: 'Fitur copy tidak tersedia di perangkat/browser ini.',
    settings: 'Pengaturan',
    alertSlug: 'Tolong Check Ketersedian Slug',
    alertChannel: 'Tolong Pilih Minimal 1 Channel',
    confirmDeleteLink: 'Anda Yakin Ingin Menghapus Link Generate ini?',
    tutorialSlugOne: 'Slug link dipakai untuk menghindari kesamaan link yang di tuju dengan user lain',
    tutorialSlugTwo: 'Anda dapat menentukan Pesan yang akan user kirimkan kepada Anda',
    tutorialSlugThree: 'Anda dapat menentukan Operator yang akan mendapatkan pesan dari link yang di klik oleh User',
    tutorialSlugFour: 'Anda Memiliki Google Tag? paste disini untuk melakukan tracking pada Google Tag Manager Anda',
    tutorialSlugFive:
      'Anda Juga Dapat Menentukan Loading saat user melakukan klik terhadap link yang anda buat dan memaksimalkan Google Tag Manager',
  },
  OperatorsTranslate: {
    confirmWant: 'Anda Yakin ingin Menghapus',
    meaningAction: 'sebagai Operator?',
  },
  coupons: {
    coupon: 'Kupon',
  },
  landing: {
    hero: {
      sendManyMsg: 'Kirim Banyak Pesan WhatsApp Hanya Dengan',
      oneClick: 'Satu Klik',
      increaseSales:
        'Tingkatkan Penjualanmu Hingga 300% dengan berinteraksi langsung dengan pelangganmu menggunakan WhatsApp.',
      tryNow: 'Coba Sekarang',
      sendMessage: 'Kirim Pesan',
    },
    howItWork: {
      title: 'Cara Kerja',
      headline: 'Bagaimana Cara Kerjanya ?',
      stepOne: {
        title: '1. Hubungkan Nomor WhatsApp Anda',
        detail: 'Scan QR Code yang telah kami siapkan, setelah itu, Anda akan terhubung dengan server kami.',
      },
      stepTwo: {
        title: '2. Pilih Kontak Penerima Broadcast Anda',
        detail: 'Silahkan pilih nomor kontak penerima yang ingin Anda kirimkan pesan tersebut.',
      },
      stepThree: {
        title: '3. Pengiriman Pesan Otomatis Dimulai',
        detail: 'Jika sudah, biarkan sistem kami yang bekerja. Anda boleh duduk manis sambil minum kopi.',
      },
    },
    features: {
      title: 'Fitur',
      headline: 'Apa Saja Fitur Canggih di SuperWA ?',
      description:
        'SuperWA adalah tool berbasis web untuk Anda sebagai pebisnis online yang dapat membantu penjualan di bisnis Anda jadi jauh lebih efektif & efisien.',
      featureOne: {
        title: 'Kirim Pesan Tanpa Batas',
        body: 'Kirim pesan ke ratusan kontak WhatsApp hanya dengan sekali klik, tanpa harus simpan nomor.',
      },
      featureTwo: {
        title: 'WhatsApp Web Multi Pengguna',
        body: 'Satu akses WhatsApp Web yang bisa digunakan oleh banyak CS Anda.',
      },
      featureThree: {
        title: 'Kustomisasi Penuh Pesan',
        body: 'Buat isi pesan Anda menjadi dinamis dengan menambahkan nama customer dan emoji.',
      },
      featureFour: {
        title: 'Sisipkan Gambar pada Pesan Broadcast',
        body: 'Buat isi pesan Anda lebih menarik lagi dengan melampirkan gambar.',
      },
      featureFive: {
        title: 'Kustomisasi Jadwal Broadcast',
        body: `Rencanakan broadcast Anda dengan langkah yang mudah. Broadcast kan pesan Anda kapanpun Anda inginkan.`,
      },
      featureSix: {
        title: 'Singkronkan Kontak dari WhatsApp',
        body: 'Hanya dengan satu klik, Anda bisa dengan mudah mengimpor ataupun mengekspor semua kontak Anda dari WhatsApp.',
      },
      featureSeven: {
        title: 'Berikan Penanda pada Customer Anda',
        body: `Kelompokkan customer Anda berdasarkan penanda yang telah dibuat.`,
      },
      featureEight: {
        title: 'Impor dan Ekspor Kontak',
        body: 'Baik secara manual maupun secara otomatis, Anda bisa memasukan kontak hany dengan sekali klik.',
      },
      featureNine: {
        title: 'Pantau Kinerja Tim Anda',
        body: 'Pantau kinerja dan kecepatan balas tim secara harian, mingguan dan bulanan.',
      },
      featureTen: {
        title: 'Penanda Pesan',
        body: 'Tandai chat untuk setiap proses pesanan Anda.',
      },
    },
    demo: {
      title: 'Demo',
      headline: 'Demo SuperWA',
      subTitle: 'Pindai dengan WA dan kirim pesan pertama anda',
      subTitlesuccess:
        'Pesan pertama anda telah berhasil terkirim, coba aplikasi kami untuk melakukan broadcast dan lebih banyak lagi',
    },
    demoLanding: {
      stepOne: {
        android: { open: 'Buka', desc: 'di HP Anda', moreOpt: `Ketuk 'Opsi Lainnya'` },
        ios: {
          open: 'Buka',
          desc: 'di HP Anda',
          moreOpt: 'Ke Menu Pengaturan',
          moreOpt2: 'WhatsApp',
        },
      },
      stepTwo: {
        android: {
          tap: 'Ketuk',
          linkDevice: 'TAUTKAN PERANGKAT',
          desc: 'Ikuti petunjuk yang ditampilkan di layar jika perangkat Anda memiliki fitur autentikasi biometrik. Jika tidak mengaktifkan autentikasi biometrik, Anda akan diminta untuk memasukkan PIN yang digunakan untuk membuka kunci telepon.',
        },
        ios: {
          desc: `Di iOS 14 dan yang lebih baru, gunakan Touch ID atau Face ID untuk membuka kunci. Jika tidak mengaktifkan autentikasi biometrik, Anda akan diminta untuk memasukkan PIN yang digunakan untuk membuka kunci telepon.`,
        },
      },
      stepThree: {
        android: {
          select: 'Ceklis opsi',
          keepSigned: 'Tetap Masuk',
          desc: {
            head: 'Pilih kotak centang di sebelah',
            body: 'di layar yang menampilkan kode QR Anda pada komputer atau Portal untuk tetap masuk pada perangkat ini.',
          },
        },
      },
      stepFour: {
        android: {
          scan: 'Pindai',
          qrCode: 'Kode QR',
          below: 'di bawah ini',
        },
      },
      note: {
        headline: 'CATATAN:',
        body: `"Jika Anda tidak bisa memindai kode QR, pastikan Anda tidak sedang mengikuti WhatsApp multi device beta. Anda bisa melihat bagaimana untuk meninggalkannya`,
        link: `disini`,
      },
    },
    testimonials: {
      title: 'Testimoni',
      headline: 'Testimoni',
      testimonialOneDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testimonialTwoDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    faq: {
      title: 'Tanya Jawab',
      subTitle: 'Kami bantu jawab pertanyaan yang banyak ditanyakan.',
      faqOne: {
        question: 'Apa itu SuperWA ?',
        answer:
          'SuperWA adalah tool yang dapat mengirimkan pesan ke banyak nomor WhatsApp hanya dengan sekali klik. Tidak hanya itu, di SuperWA juga ada fitur-fitur canggih seperti Group Contacts Grabber, Live Chat Widget, WA Link Rotator dan lainnya.',
      },
      faqTwo: {
        question: 'Masa sih saya butuh SuperWA ?',
        answer:
          'Anda berhak membuat keputusan Anda sendiri. Seperti membiarkan diri Anda tertinggal jauh dari pesaing bisnis Anda yang lain, misalnya. Boleh.',
      },
      faqThree: {
        question: 'Apakah SuperWA cocok untuk saya ?',
        answer:
          'SuperWA cocok untuk marketer dari berbagai kalangan (Pemilik Toko Online, Digital Advertiser, Blogger, Affiliate Marketer, dll) yang menggunakan WhatsApp sebagai sarana promosi.',
      },
      faqFour: {
        question: 'Apakah sudah ada database nomor WhatsApp-nya ?',
        answer:
          'Tidak ada. SuperWA bukan merupakan platform yang menyediakan nomor WhatsApp untuk dikirimkan promosi. Semua nomor WhatsApp harus berasal dari Anda (sebagai pelaku bisnis), dan nomor harus didapatkan dengan cara yang legal.',
      },
      faqFive: {
        question: 'Apakah SuperWA perlu di-install ke komputer ?',
        answer:
          'Tidak perlu. SuperWA adalah tool berbasis web, yang bisa diakses menggunakan platform apapun (Desktop/Mobile) atau (Windows, Mac OS, Ubuntu, Android, iOS, dll). Selama perangkat yang Anda gunakan memiliki browser, maka SuperWA akan selalu bisa diakses.',
      },
      faqSix: {
        question: 'Saya gaptek, Apakah ada panduan penggunaannya ?',
        answer:
          'Sangat lengkap. Di member area, Anda bisa pergi ke menu “Panduan Pengguna”, di sana tersedia video cara penggunaan yang bisa Anda pelajari untuk menggunakan SuperWA. Masih bingung? Tenang saja. Ada tim kami yang siap membantu menjawab semua pertanyaan Anda.',
      },
      faqSeven: {
        question: 'Apakah ada garansi uang kembali / refund ?',
        answer: 'TIDAK ADA',
      },
      faqEight: {
        question: 'Jika nomor saya diblokir, Apakah bisa refund ?',
        answer:
          'Tidak ada tool pihak ketiga manapun yang 100% aman dari blokir WhatsApp. Pengguna aktif SuperWA ada 1.000+ orang setiap harinya. Jika Anda diblokir oleh pihak WhatsApp, tidak bisa refund.',
      },
    },
  },
  eform: {
    add: 'Memulai formulir baru',
    notFound: 'Belum ada formulir',
    notFoundAction: 'Klik + untuk membuat formulir baru.',
    title: 'Judul Formulir',
    titlePlaceholder: 'Formulir tanpa judul',
    titleEmpty: 'Judul wajib diisi.',
    description: 'Deskripsi Formulir',
    descriptionPlaceholder: 'Deskripsi formulir',
    discard: 'Batalkan formulir ini?',
    notFoundById: 'eForm tidak ditemukan',
    copyUrlSurvey: 'Salin tautan survey',
    openSurvey: 'Buka survey',
    surveyAnalytics: 'Analitik survey',
    savePdfSurvey: 'Simpan PDF survey',
  },
}
